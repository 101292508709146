<template>
  <patches-template
    :patches="patches"
    :patches-type="patchesType"
    :is-patches-type-visible="isPatchesTypeVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import PatchesTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/patches/PatchesTemplate';
import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes, no } from '@/modules/questionnaire/api/helpers';

const fieldsToReset = [
  'patchCauses',
  'firstPatchOccurrences',
  'patchLocations',
  'otherPatchLocations',
  'textureDiscomfort'
];

const { field, requiredField } = fieldBuilder;

const FIELDS = [
  ...fieldsToReset.map(field),
  requiredField('patches'),
  requiredField('patchesType')
];

export default {
  name: 'Patches',
  components: {
    PatchesTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  computed: {
    isPatchesTypeVisible() {
      return yes(this.patches);
    }
  },
  watch: {
    patches(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName([...fieldsToReset, 'patchesType']);

        this.showNextStep();
      } else {
        this.scrollTo('#patches-type');
      }
    },
    patchesType(newValue, oldValue) {
      if (!oldValue) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'patchesType') {
        return this.isPatchesTypeVisible;
      }

      return true;
    }
  }
};
</script>
